import React from "react";

import List from "../custom-widgets/list";
import Image from "../custom-widgets/image";
import Button from "../custom-widgets/button";
import VimeoVideoFrame from "../custom-widgets/vimeo-video-frame";

const Heading = (heading) => <h2 className={heading.class}>{heading.text}</h2>;

const Subheading = (subheading) => <h3 className={subheading.class}>{subheading.text}</h3>;

const Paragraph = (paragraph) => <p className={paragraph.class}>{paragraph.text}</p>;

const ColumnContent = (props) => {
  // console.log("ColumnContent props: ", props);

  let content = props;
  return (
    <>
      {props.components && props.components.length > 0 ? (
        props.components.map((component) => {
          return (
            <div className="content-wrapper" key={component.id}>
              {component.heading && <Heading {...component.heading} />}
              {component.subheading && <Subheading {...component.subheading} />}
              {component.paragraph && <Paragraph {...component.paragraph} />}
              {component.list && <List {...component.list} />}
              {component.image && <Image {...component.image} />}
              {component.button && (
                <div className={component.button.containerClass}>
                  <Button {...component.button} />
                </div>
              )}
            </div>
          );
        })
      ) : (
        <div className="content-wrapper">
          {content.imgTop && <Image {...content.imgTopData} />}
          {content.heading && (
            <h2 className={content.headingClass} dangerouslySetInnerHTML={{ __html: content.heading }} />
          )}
          {content.textTop && (
            <p className={content.textTopClass} dangerouslySetInnerHTML={{ __html: content.textTop }} />
          )}
          {content.hasList && <List {...content.listData} />}
          {content.textBottom && (
            <p className={content.textBottomClass} dangerouslySetInnerHTML={{ __html: content.textBottom }} />
          )}
          {content.imgBottom && <Image {...content.imgBottomData} />}
          {content.videoBottom && <VimeoVideoFrame vimeoId={content.videoId} />}
        </div>
      )}
    </>
  );
};
export default ColumnContent;

ColumnContent.defaultProps = {
  components: [],
  // TODO: port all the personal checking pages to use the components array instead of the props below:
  headingClass: "text-black",
  heading: "",
  textTopClass: "text-black",
  textTop: "",
  hasList: false,
  textBottomClass: "text-black",
  textBottom: "",
  listData: {},
  imgTop: false,
  imgTopData: {},
  imgBottom: false,
  imgBottomData: {}
};
