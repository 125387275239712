import React from "react";

import { GatsbyImage } from "gatsby-plugin-image";

const Image = (props) => {
  return (
    <div className={props.containerClass}>
      {props.imgFixed ? (
        <GatsbyImage image={props.imgVariable} className={`fixed-image ${props.class}`} alt={props.altText} />
      ) : (
        <div
          className="custom-image-sizer text-center"
          style={{ maxHeight: `${props.maxHeight}`, height: `${props.height}` }}
        >
          <GatsbyImage image={props.imgVariable} className={props.class} alt={props.altText} />
        </div>
      )}
    </div>
  );
};
export default Image;

Image.defaultProps = {
  id: 1,
  containerClass: "", // "d-flex justify-content-center",
  class: "",
  imgFixed: false,
  imgVariable: "",
  source: "",
  altText: "",
  maxHeight: "400",
  maxWidth: "300",
  height: "",
  width: ""
};
