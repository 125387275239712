import React from "react";

import Image from "./image";

const Media = (props) => {
  let content = props;
  return (
    <div className="media">
      {content.imgLocation === "left" && <Image {...content.imgData} />}
      <div className="media-body ml-3 ">
        {content.heading && <h3 className={content.headingClass}>{content.heading}</h3>}
        {content.textTop && <p className={content.textTopClass}>{content.textTop}</p>}
        {content.linkText && (
          <a className={content.linkClass} href={content.linkUrl}>
            {content.linkText}
          </a>
        )}
        {content.textBottom && <p className={content.textBottomClass}>{content.textBottom}</p>}
      </div>
      {content.imgLocation === "right" && <Image {...content.imgData} />}
    </div>
  );
};
export default Media;

Media.defaultProps = {
  headingClass: "text-black",
  heading: "",
  textTopClass: "text-black",
  textTop: "",
  linkClass: "",
  linkUrl: "",
  linkText: "",
  textBottomClass: "text-black",
  textBottom: "",
  listData: {},
  imgLocation: "left", // "left", "right", "none"
  imgData: {},
  iconLocation: "left", // "left", "right", "none"
  iconData: {}
};
