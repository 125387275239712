import React from "react";

import ColumnContent from "./column-content";
import Media from "../custom-widgets/media";

// NOTE: while this component was designed for just two columns, it can be used for as many columns as you like.
const TwoColumn = (props) => {
  let data = props;
  return (
    <section className={data.sectionClass} id={data.sectionId}>
      <div className="container">
        {data.sectionHeading && (
          <h2 className={data.sectionHeadingClass} dangerouslySetInnerHTML={{ __html: data.sectionHeading }} />
        )}
        <div className="row">
          {data.columns.map((col) => (
            <div key={col.id} className={col.colClass}>
              {col.contentType === "media" ? <Media {...col.content} /> : <ColumnContent {...col.content} />}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default TwoColumn;

TwoColumn.defaultProps = {
  sectionClass: "bg-white",
  sectionId: "",
  sectionHeadingClass: "text-black",
  sectionHeading: "",
  columns: [
    {
      id: 1,
      colClass: "col-sm-12 col-md-6",
      contentType: "normal", // "media",
      content: {}
    },
    {
      id: 2,
      colClass: "col-sm-12 col-md-6",
      contentType: "normal", // "media",
      content: {}
    }
  ]
};
